<template>
  <div>
    <v-card class="mt-3" flat>
      <v-card-title class="mb-6">
        <v-text-field
          v-model="search"
          class="pt-0 mt-0"
          width="300"
          hide-details
          single-line=""
          placeholder="Busca (responsável ou ID da venda)"
          append-icon="search"
          outlined
        />
        <v-spacer />
        <mf-button :disabled="$apollo.loading" label="Adicionar Ação" icon="settings" @click="openSalesTypeDialog" />
      </v-card-title>
      <v-card-text>
        <v-data-table
          :loading="$apollo.loading"
          :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
          :server-items-length="maxRecords"
          :options.sync="options"
          :headers="headers"
          :items="activeProductsData"
          :search="search"
        >
          <template v-slot:[`item.type`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                <v-icon class="mr-2" x-small :color="mapColor(item.type)">mdi-brightness-1</v-icon>{{ mapSaleType(item.type) }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ parseDate(item.created_at) }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.billing_date`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ parseBillingDate(item.billing_date) }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.total_items`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.items.length || 0 }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.sale_total`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ getSaleTotal(item.items) }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.observation`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                <v-tooltip v-if="item.observation" max-width="350" top color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <span class="limitText" v-bind="attrs" v-on="on">{{ item.observation }}</span>
                  </template>
                  <span>{{ item.observation }}</span>
                </v-tooltip>
                <span v-else> - </span>
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.contract_link`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                <mf-button v-if="item.contract_link" text label="Ver contrato" @click="openContract(item.contract_link)" />
                <span v-else> - </span>
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.options`]="{ item }">
            <v-menu :close-on-content-click="false">
              <template #activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="saleDetails(item)">
                  <v-list-item-title>{{ 'Detalhe ' + labelDetails(item.type) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <sales-type-dialog v-if="showSalesTypeDialog" :has-new-sale="haveNewSale" @input="closeSalesTypeDialog" @select-action="selectActionType" />
    <sale-details-dialog v-if="saleDetailsDialog" :units="finalUnits" :sale-id="saleId" :dialog="saleDetailsDialog" @close="closeDialog" />
    <downsell-details-dialog v-if="downsellDetailsDialog" :sale-id="saleId" :dialog="downsellDetailsDialog" @close="closeDialog" />
  </div>
</template>

<script>
import {
  QUERY_GET_SALES,
  QUERY_GET_RETAILER,
  QUERY_GET_AVAILABLE_PRODUCTS_COUNT,
  MUTATION_UNSET_CHURN,
  QUERY_GET_CLIENT_UNITS_BY_CLIENT
} from '@/modules/retailers/graphql'
export default {
  components: {
    SalesTypeDialog: () => import('../../../../../components/atomic-components/molecules/SalesTypeDialog.vue'),
    SaleDetailsDialog: () => import('../dialogs/SaleDetailsDialog.vue'),
    DownsellDetailsDialog: () => import('../dialogs/DownsellDetailsDialog.vue')
  },
  props: {
    retailer: {
      type: Object
    }
  },
  data: () => ({
    showSalesTypeDialog: false,
    search: '',
    options: {},
    maxRecords: 0,
    activeProductsData: [],
    saleId: null,
    saleDetailsDialog: false,
    downsellDetailsDialog: false,
    retailerData: {},
    haveNewSale: false,
    clientUnitsData: []
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Data Ação',
          value: 'created_at',
          align: 'center'
        },
        {
          text: 'Data de Início do Faturamento',
          value: 'billing_date',
          align: 'center'
        },
        {
          text: 'Responsável',
          value: 'owner',
          align: 'center'
        },
        {
          text: 'Tipo de Ação',
          value: 'type',
          align: 'center'
        },
        {
          text: 'Quantidade de Itens',
          value: 'total_items',
          align: 'center'
        },
        {
          text: 'Total da Ação',
          value: 'sale_total',
          align: 'center'
        },
        {
          text: 'Observação',
          value: 'observation',
          align: 'center'
        },
        {
          text: 'Contrato',
          value: 'contract_link',
          align: 'center'
        },
        {
          text: '',
          value: 'options',
          align: 'center'
        }
      ]
    },
    accounts() {
      return this.retailer?.accounts ? this.retailer.accounts.join(',') : ''
    },
    finalUnits() {
      return this.clientUnitsData.map(i => {
        return { cnpj: i?.cnpj.replace(/[^0-9]/g, ''), name: i?.name }
      })
    },
    disableButton() {
      if (this.retailerData.active === 'undefined') {
        return true
      }
      return !this.retailerData.active
    }
  },
  apollo: {
    sales: {
      query: QUERY_GET_SALES,
      fetchPolicy: 'network-only',
      variables() {
        return Object.assign(
          {},
          { client_id: this.$route.params.id },
          {
            pagination: {
              page: this.options.page || 1,
              pageSize: this.options.itemsPerPage || 10,
              search: this.search || null,
              sort: this.options.sortBy || ['_id'],
              sortDirection: this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC'))
            }
          }
        )
      },
      update({ sales: { count, data } }) {
        this.maxRecords = count
        this.activeProductsData = data
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      },
      error(error) {
        console.log(error)
      }
    },
    verifyIfHasNewSale: {
      query: QUERY_GET_AVAILABLE_PRODUCTS_COUNT,
      fetchPolicy: 'network-only',
      variables() {
        return Object.assign(
          {},
          { client_id: this.$route.params.id },
          { filters: {} },
          {
            pagination: {
              page: 1,
              pageSize: 9999,
              search: '',
              sort: ['_id'],
              sortDirection: 'ASC'
            }
          }
        )
      },
      update({ availableProducts: { count, data } }) {
        let haveActiveProduct = data.filter(item => item.active)
        this.haveNewSale = !(count === 0 || haveActiveProduct.length === 0)
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    },
    client: {
      query: QUERY_GET_RETAILER,
      fetchPolicy: 'network-only',
      variables() {
        return { client_id: this.$route.params.id }
      },
      update({ client }) {
        this.retailerData = client
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    },
    clientUnits: {
      query: QUERY_GET_CLIENT_UNITS_BY_CLIENT,
      fetchPolicy: 'no-cache',
      variables() {
        return { client_units: { client_id: this.$route.params.id } }
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      },
      update({ clientUnits }) {
        this.clientUnitsData = clientUnits
      }
    }
  },
  mounted() {
    this.clientId = this.$route.params.id
  },
  methods: {
    selectActionType(data) {
      this.$router.push({ name: 'create-sale', query: data })
    },
    saleDetails(sale) {
      this.saleId = sale._id
      if (this.showSaleDetails(sale)) {
        this.saleDetailsDialog = true
      } else {
        this.downsellDetailsDialog = true
      }
    },
    labelDetails(type) {
      const mapLabels = {
        cross_sell: 'do Cross Sell',
        discount: 'da Renegociação de MRR',
        downsell: 'do Downsell',
        new_sale: 'da Nova Venda',
        revert_temporary_churn: 'do Retorno do Abono',
        temporary_churn: 'do Abono Temporário',
        upsell: 'do Upsell'
      }
      return mapLabels[type]
    },
    retailerIsActive() {
      return !this.retailerData?.active || true
    },
    async openSalesTypeDialog() {
      if (!this.retailerData.active) {
        const confirmation = await this.$confirm({
          title: 'Aviso',
          message: 'A conta está inativa. Para realizar uma nova ação, é necessário ativá-la. Deseja ativá-la agora?',
          confirmText: 'Sim',
          cancelText: 'Não'
        })
          .then(() => true)
          .catch(() => false)
        if (!confirmation) return
        try {
          await this.$apollo.mutate({
            mutation: MUTATION_UNSET_CHURN,
            variables: {
              client_id: this.retailerData._id
            },
            context: {
              uri: this.$microservicesUrls['crm']
            }
          })
          setTimeout(() => {
            this.dialog_action = false
            this.dialog = false
            this.$alert({
              alert_message: `Churn Removido com sucesso`,
              alert_title: 'Sucesso!',
              alert_color: 'success',
              alert_icon: 'mdi-check-circle'
            })
          }, 300)
        } catch {
          this.$alert({
            alert_message: `Ops, parece que houve algum erro`,
            alert_title: 'Erro!',
            alert_color: 'error',
            alert_icon: 'mdi-close-circle'
          })
        }
      }
      this.showSalesTypeDialog = true
    },
    closeSalesTypeDialog() {
      this.showSalesTypeDialog = false
    },
    parseDate(date) {
      return this.$moment(date).format('DD/MM/YYYY - HH:mm:ss')
    },
    parseBillingDate(billingDate) {
      const date = this.$moment(billingDate).format('DD/MM/YYYY - HH:mm:ss')
      if (date === 'Data inválida') return '-'
      return date
    },
    parseCurrency(value) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      })

      return formatter.format(value)
    },
    mapSaleType(type) {
      const mapType = {
        new_sale: 'Nova venda',
        upsell: 'Upsell',
        downsell: 'Downsell',
        temporary_churn: 'Abono temporário',
        revert_temporary_churn: 'Retorno Abono Temporário',
        cross_sell: 'Cross Sell',
        discount: 'Renegociação de MRR'
      }

      return mapType[type]
    },
    mapColor(type) {
      const mapType = {
        new_sale: 'info',
        upsell: 'success',
        downsell: 'error',
        temporary_churn: 'warning',
        revert_temporary_churn: 'success',
        discount: 'red lighten-2',
        cross_sell: 'success'
      }

      return mapType[type]
    },
    closeDialog() {
      this.saleId = null
      this.saleDetailsDialog = false
      this.downsellDetailsDialog = false
    },
    getSaleTotal(items) {
      let total = 0
      items.map(item => {
        if (item.total_value) total += item.total_value
      })

      return this.parseCurrency(total)
    },
    openContract(link) {
      return window.open(link, '_blank')
    },
    showSaleDetails(sale) {
      return (
        sale?.type === 'new_sale' || sale?.type === 'upsell' || sale?.type === 'temporary_churn' || sale?.type === 'cross_sell' || sale?.type === 'discount'
      )
    }
  }
}
</script>

<style scoped>
.limitText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  cursor: context-menu;
}
</style>
